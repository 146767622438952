@import url('./assets/css/header.css');
@import url('./assets/css/home.css');

@import url(https://db.onlinewebfonts.com/c/a64ff11d2c24584c767f6257e880dc65?family=Helvetica+Regular);

@font-face {
    font-family: "Helvetica Regular";
    src: url("https://db.onlinewebfonts.com/t/a64ff11d2c24584c767f6257e880dc65.eot");
    src: url("https://db.onlinewebfonts.com/t/a64ff11d2c24584c767f6257e880dc65.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/a64ff11d2c24584c767f6257e880dc65.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/a64ff11d2c24584c767f6257e880dc65.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/a64ff11d2c24584c767f6257e880dc65.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/a64ff11d2c24584c767f6257e880dc65.svg#Helvetica Regular")format("svg");
}

/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


body
{
  /* font-family: "Lato", serif; */
  font-family: "Montserrat", sans-serif;
  color: #2C354C;
  overflow-x: hidden;
}


h1, h2, h3, h4, h5, h6
{
  color: #2C354C;
}

p{
  color: #2C354C;
}

.HelveticaRegular
{
font-family: "Helvetica Regular";
}

h1 { font-size: 45px; }
h2 { font-size: 36px; }
h3 { font-size: 28px; }
h4 { font-size: 22px; }
h5 { font-size: 18px; }
h6 { font-size: 14px; }

.fs-1{ font-size: 45px !important;; }
.fs-2{ font-size: 32px !important;; }
.fs-3{ font-size: 28px !important;; }
.fs-4{ font-size: 22px !important;; }
.fs-5{ font-size: 18px !important; }
.fs-6{ font-size: 14px !important;; }
.fs-7{ font-size: 40px !important;; }

.fs-9{ font-size: 9px; }
.fs-10{ font-size: 10px; }
.fs-11{ font-size: 11px; }
.fs-12{ font-size: 12px; }
.fs-14{ font-size: 14px; }
.fs-15{ font-size: 15px; }
.fs-16{ font-size: 16px; }
.fs-18{ font-size: 18px !important; }

.fs-20{ font-size: 20px; }
.fs-24{ font-size: 24px; }
.fs-26{ font-size: 26px; }
.fs-34{ font-size: 34px !important; }
.fs-36{ font-size: 36px !important; }
.fs-38{ font-size: 38px; }
.fs-40{ font-size: 40px; }

/*letter spacing*/
.ls-0{ letter-spacing: 0%; }
.ls-minus-02 { letter-spacing: -0.2px; }
.ls-minus-05{ letter-spacing: -0.5% ; }
.ls-minus036{ letter-spacing: -0.36;  }
.ls-minus-064{ letter-spacing: -0.64; }
.ls-minus-032 { letter-spacing: -0.32px; }
.ls-minus-045{ letter-spacing: -0.45px; }
.ls-1 { letter-spacing: 1%; }

/*width*/
.w-250
{
  width: 250px;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.width-fit
{
  width: fit-content;
}

.fw-100 { font-weight: 100; }
.fw-200 { font-weight: 200; }
.fw-300 { font-weight: 300; }
.fw-400 { font-weight: 400; }
.fw-500 { font-weight: 500 !important; }
.fw-600 { font-weight: 600; }
.fw-700 { font-weight: 700 !important; }
.fw-800 { font-weight: 800; }
.fw-900 { font-weight: 900; }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Background*/
.bg-E2F1FE
{
  background-color: #E2F1FE;
}

.bg-EFFDFF
{
  background-color: #EFFDFF;
}

.bg-blue-gradient
{
  background: linear-gradient(180deg, #067BB1 0%, #04467A 100%);
}

.bg-blue-gradient-two
{
  background: linear-gradient(244.76deg, #067BB1 33.98%, #04467A 91.69%);

}

/*color*/
.text-ee0000
{
  color: #ee0000;
}

.text-00A1E0
{
  color: #00A1E0;
}

.text-2C354CB2
{
  color: #2C354CB2;
}

.text-252B42BF
{
  color: #252B42BF;
}

.text-252B42
{
  color: #252B42;
}

.text-028bc6
{
  color: #028bc6;
}

/*margin*/
.mt-6
{
  margin-top: 4.5rem;
}

/*Padding*/

.pt-90
{
  padding-top: 90px;
}

.pb-90
{
  padding-bottom: 90px;
}


.pt-80
{
  padding-top: 80px;
}

.pb-80
{
  padding-bottom: 80px;
}

.pt-60
{
  padding-top: 60px;
}

.pt-50
{
  padding-top: 50px;
}

.pb-60
{
  padding-bottom: 60px;
}

.pt-70
{
  padding-top: 70px;
}

.pb-70
{
  padding-bottom: 70px;
}

.p-23
{
  padding: 2.3rem;
}

@media (max-width: 600px) {
     .p-sm-23{
          padding: 1.3rem;
     }
  
}
/*shadow*/
.w8-bx-shadow
{
  box-shadow: 0px 0px 15.2px 0px #ABBED166;
}

.box-shadow-one{
  box-shadow: 0px 4px 24px 0px #12121221;
}

.box-shadow-two
{
  box-shadow: 0px 4px 25px 0px #00000040;
}

.box-shadow-three
{
  box-shadow: 0px 0px 8.81px 0px #0000001C;
}

.box-shadow-four
{
  box-shadow: 0px 0px 5px 0px #00000030;
}

.box-shadow-five
{
  box-shadow: 0px 4px 4px 0px #00000040;
}

/*line height*/
.lh-13 { line-height: 1.3; }

/*form control*/
.form-control
{
  border: 1px solid #CECECE;
  padding: .5rem .75rem;
  margin-bottom: 5px;
    font-size: 12px;

}

.form-select
{
  border: 1px solid #CECECE;
  padding: .5rem .75rem;
  margin-bottom: 5px;
    font-size: 12px;
    color: #252B4299;
  }

.form-select option{
  font-size: 12px;
  color: #252B4299 !important;
}


.form-control::placeholder
{
  letter-spacing: -0.36px;
  font-weight: 500;
  font-size: 12px;
  color: #252B4299;
}

.form-check-label {
  font-size: 13px;
  font-weight: 600;
}

.form-check .form-check-input {
  font-size: 12px;
  border: 1px solid #252B42;
}

.form-check-input:checked {
  background-color: #e00 !important;
}

.form-check-input
{
  margin-top: 7px;
}

.form-check
{
  padding-left: 15px;
}

.form-label
{
  color: #252B42;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
}

/*border*/
.border-252B42{
  border-color: #252B42;
}

.border-067BB1
{
  border-color: #067BB1 !important;
}

