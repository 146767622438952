.navbar .nav-link {
    margin-right: 20px;
    font-weight: 700;
    /* font-family: Lato; */
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    line-height: 24px;
    Letter-spacing: -0.36px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;
  }
  
  .navbar .nav-link.active {
    border-bottom: 4px solid #ee0000;
    color: #000 !important;
  }

  .navbar_menu {
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    transition: all 0.4s;
  }
  
  .navbar-scrolled {width: 100%;
    transform: translate3d(0, 0, 0);
    animation-name: fade-in-down;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    transition: all 0.4s;
    box-shadow: 0px 4px 96.3px 0px #FFFFFF40;

  }

  .fade-in-down {
    opacity: 0; /* Ensures it starts invisible */
    animation-name: fade-in-down;
    animation-duration: 1s;
    animation-fill-mode: forwards; /* Keeps the final state */
    transition: all 0.4s ease-in-out; /* Adds smooth transitions for other properties */
  }
  

  @keyframes fade-in-down {
    0% {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  
  .offcanvas .nav-link {
    padding: 10px 0;
    font-size: 18px;
  }
  
  .offcanvas .nav-link.active {
    border-bottom: 2px solid rgb(238, 0, 0);
    width: fit-content;
  }

nav
  {

    box-shadow: 0px 4px 8px 0px #00000026;
    box-shadow: 0px 4px 96.3px 0px #FFFFFF40 !important;

    background-color: #fff;
    z-index: 1;
  }

  .navbar
{
  padding: 18px 0;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #00000030 !important;

}

.nav-dropdown-menu .dropdown-menu {
  box-shadow: 0px 4px 25px 0px #00000066;
  border: none;
  /* font-family: Lato; */
  font-family: "Montserrat", sans-serif;

font-size: 12px;
font-weight: 600;
margin-bottom: 5px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000;
padding: 10px 0;
border-radius: 10px;
margin-top: 10px;
}

.nav-dropdown-menu .dropdown-menu  .dropdown-item
{
  font-weight: 600;
}

.navbar_menu .nav-link:hover {
  border-bottom: 4px solid #E00;
}

.navbar_menu .nav-link {
  border-bottom: 4px solid #fff;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .6em;
  vertical-align: middle;
  content: "";
  border-top: none;
  border-right: none;
  border-bottom: 0;
  border-left: none;
  /* background-image: url(/src/assets/images/arrow.png);
  width: 10px;
  height: 10px;
  background-size: 100%;
  background-repeat: no-repeat; */
}

.dropdown-toggle svg{
font-size: 12px;
margin-left: 4px;
}

.offcanvas .nav-link {
  padding: 0.7rem 0;
  font-size: 15px;
  color: #000;
margin-bottom: 10px;
    font-weight: 600;
}

.offcanvas .accordion-header button
{
  font-weight: 600;
}

.accordion-button {
  padding: 1rem 0;
  font-size: 16px;
}



.accordion-button:not(.collapsed) {
  color: #e00;
  background-color: transparent;
  box-shadow: unset;
  border-bottom: 1px solid #e00;
}

.offcanvas .accordion-body .nav-link {
  padding: 0.4rem 0;
  font-size: 13px;
  font-weight: 400;
}

.offcanvas  .accordion-button:focus {
  box-shadow: unset;
}

.offcanvas .accordion-button::after {
  background-size: 1rem;
}

.offcanvas {
  background-color: #000;
}


.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #e00;
  box-shadow: unset;
  border-bottom: 1px solid rgb(8, 149, 204);
  padding-left: 10px;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-flush > .accordion-item > .accordion-collapse {
  background: linear-gradient(180deg, #067BB1 13.71%, #04467A 100%);
  border-top: 1px solid #fff;
}

.offcanvas-title {
  color: #fff;
}

.offcanvas .nav-link {
  color: #fff;
}

.offcanvas .accordion-button {
  padding: 1rem 0;
  font-size: 16px;
}
.offcanvas .accordion-button {
  color: #fff;
  background-color: #067BB1;
  padding-left: 10px;
}

.offcanvas .accordion-item {
  border: none;
}

.offcanvas .accordion-button.collapsed
{
  padding-left: 5px;
  background-color: #2C354C;
  padding-left: 0px;
  margin-right: 10px;
}


.offcanvas .accordion-button::after {
  margin-right: 10px;
}

.offcanvas .collapsed.accordion-button::after {
  margin-right: 0px;
}



.offcanvas .accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.offcanvas .btn-close {
  --bs-btn-close-color: #fff; /* White icon color */
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e"); /* White close icon */
  --bs-btn-close-opacity: 1; /* Fully visible */
  --bs-btn-close-hover-opacity: 0.8; /* Slightly dimmed on hover */
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25); /* White focus shadow */
}

.nav-dropdown-menu.active .nav-link{
  border-bottom: 4px solid #ee0000; 
}

.dropdown-item{
  position: relative;
}

.dropdown-item.active
{
  background-color: #fff;
  color: #000;
}

.dropdown-item.active::before
{
  background-color: #00A1E0;
  position: absolute;
  left: 0;
  width: 4px;
  height: 26px;
  border-radius: 8px;
  content: "";
  top: 0;
}

.offcanvas.offcanvas-start {
  top: 20px;
  left: 0;
  width: 300px;
  transform: translateX(-100%);
  border-radius: 0;
}

.offcanvas {
  background-color: #2C354C;
}