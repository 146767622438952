header.masthead {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #fff;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100%;
  line-height: normal;
}

header.masthead.subPageHead {
  /* background: linear-gradient(180deg, #067BB1 0%, #04467A 100%); */
  /* box-shadow: 0px 4px 96.3px 0px #FFFFFF40; */

}

header.masthead .masthead-subheading {
  font-size: 1.5rem;

  font-family: Lato;
  font-family: "Montserrat", sans-serif;

  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #252B42;

}

header.masthead .masthead-heading {
  font-size: 3.25rem;
  font-weight: 700;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}


@media (min-width: 1200px) {
  .white-shadow-box {
    margin-bottom: 20px;
  }
}

@media (min-width: 991px) {
  .white-shadow-box {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  header.masthead .masthead-heading {
    font-size: 2.25rem;
  }
}



@media (min-width: 768px) {
  header.masthead {
    padding-top: 6rem;
    padding-bottom: 6rem;
    position: relative;
    z-index: 0;
  }

  header.masthead.subPageHead,
  header.masthead.about-area,
  header.masthead.contact-area {
    padding-top: 5rem;
    padding-bottom: 7rem;
  }

  header.masthead.subPageHead {
    padding-top: 5rem;
    padding-bottom: 7rem;
  }


  header.masthead .shape1 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  header.masthead.subPageHead .shape3 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    bottom: 0;
  }

  header.masthead.subPageHead .shape4 {
    position: absolute;
    top: 0;
    right: 30px;
    z-index: -1;
    object-fit: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
  }

  header.masthead.subPageHead .shape5 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    object-fit: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
  }

  header.masthead .shape2 {
    position: absolute;
    bottom: 0;
    right: 0;
    Opacity: 15%;
    z-index: -1;
  }

  header.masthead .masthead-subheading {
    font-size: 2.25rem;
    font-family: Lato;
    font-family: "Montserrat", sans-serif;

    font-size: 20px;
    font-weight: 400;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }

  header.masthead .masthead-heading {
    font-size: 4.5rem;
    font-weight: 800;
    /* font-family: Lato; */
    font-family: "Montserrat", sans-serif;

    font-size: 45px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #252B42;
    line-height: normal;
  }
}


/*main.css*/
p {
  /* font-family: Lato; */
  font-family: "Montserrat", sans-serif;

  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.btn-danger {
  border-radius: 40px;
  background-color: #EE0000;
  font-family: Lato;
  font-family: "Montserrat", sans-serif;

  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 12px 30px;
}

.btn-outline-danger {
  box-shadow: 0px 4px 20px 0px #00000040;
  border-radius: 40px;
  background-color: #fff;
  color: #EE0000;
  font-family: Lato;
  font-family: "Montserrat", sans-serif;

  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 10px 20px;
}

.btn-outline-danger:hover {
  color: #fff;
}


.btn-outline-danger {
  border-radius: 40px;
  border-color: #fff;
  background-color: #fff;
  font-family: Lato;
  font-family: "Montserrat", sans-serif;

  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #EE0000;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 10px 20px;
}

.section-heading {
  font-family: Lato;
  font-family: "Montserrat", sans-serif;

  font-size: 1.6rem;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  position: relative;
  padding-bottom: 15px;
}

/* .section-heading::after {
  position: absolute;
  content: "";
  background: #E00;
  width: 35px;
  height: 4px;
  border-radius: 40px;
  bottom: 0;
  left: 0;
} */

.bordered-text::after {
  position: absolute;
  content: "";
  background: #E30016;
  width: 75%;
  height: 6px !important;
  left: 0;
  bottom: -16px;
  right: 0;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .bordered-text::after {
    height: 5px !important;
    bottom: -8px; /* Increase the gap between the text and the border */
  }
  .section-heading
  {
    line-height: inherit !important;
  }
}


@media (max-width: 600px) {
  .bordered-text::after {
    height: 4px !important;
    bottom: -2px;
  }
}


.bordered-small {
  position: relative;
}

.bordered-small::after {
  position: absolute;
  content: "";
  background: #E30016;
  width: 100%;
  height: 3px !important;
  left: 0;
  bottom: -12px;
  right: 0;
}

.bordered-text-25::after {
  width: 25%;
}

.bordered-text-minus::after {
  bottom: -2px;
}

.bordered-text-start::after {
  margin: unset;
}

.bordered-text-w-100::after {
  width: 100%;
}

.bordered-text {
  position: relative;
  z-index: 0;
  display: inline-block;
}

header.masthead {
  overflow: hidden !important;
}

.page-section {
  padding: 2rem 0;
}

.offer-box h3 {
  font-family: Lato;
  font-family: "Montserrat", sans-serif;

  font-size: 22px;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  margin-bottom: 10px !important;
}

.offer-box {
  background-size: 100%;
  height: 200px;
  background-repeat: no-repeat;
  position: relative;
  background: linear-gradient(180deg, #067BB1 0%, #04467A 100%);
}

.offer-box::after {
  background-image: url('/src/assets/images/Ellipse-175.png');
  position: absolute;
  content: "";
  right: -20px;
  top: 0;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
}

.offer-box p {
  color: #D9D9D9;
  font-size: 10px;
  padding-right: 25px;
  font-weight: 400;
}

.offer-box {
  padding: 10px;
}

.h-bx {
  height: 200px;
}

.offer-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* Align children at the bottom */
  height: 100%;
  /* Ensure the box takes up the full height of the column */
  position: relative;
}

.offer-box h3,
.offer-box p {
  margin: 0;
  /* Reset margins */
}

.offer-box .arrow {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 22px;
  display: none;
}

.offer-box:hover .arrow {
  display: block;
}

.offer-box {
  overflow: hidden;
  z-index: 0;
}

.offer-box:hover::before {
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
}

.bg_f8f8f8 {
  background-color: #f8f8f8;
}


.sets-list h3 {
  font-family: Lato;
  font-family: "Montserrat", sans-serif;

  font-size: 18px;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  margin-bottom: 5px !important;
}

.sets-list p {
  font-size: 14px;
  color: #464646;
}

.sets-list {
  margin-bottom: 25px;
  position: relative;
  padding-left: 25px;
}

.sets-list::before {
  background-color: #E00;
  border-radius: 40px;
  width: 3px;
  height: 100%;
  left: 0;
  content: "";
  position: absolute;
}

/* .quote-icon {
  position: absolute;
  width: 100px;
  top: -100px;
} */

.quote-icon {
  width: 100px;
}

.client-quote {
  background: linear-gradient(180deg, #067BB1 13.71%, #04467A 100%);
  padding: 15px;
  border-radius: 20px;
  min-height: 250px;
}


.client-quote p {
  color: #fff;
  font-size: 11px;
  font-weight: 300;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 20px;
  letter-spacing: 0;
  line-height: 18px;
}

.client-detail h5 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 800;
  margin-bottom: 0px;
  letter-spacing: 0;
}

.client-detail p {
  font-family: "Montserrat", sans-serif;

  font-size: 10px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  letter-spacing: 0;
}

.client-quote svg {
  color: #FFC153;
  font-size: 16px;
  padding-right: 1px;
}

.client-review {
  margin-bottom: 15px;
}

.client-detail img {
  width: auto;
  height: 30px;
}

.client-detail {
  justify-content: space-between;
}

.section-heading-center {
  text-align: center !important;
}

.section-heading-center::after {
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.help-box {
  box-shadow: 0px 4px 24px 0px #12121221;
  border-radius: 20px;
  padding: 50px 30px 20px 30px;
  text-align: center;
  position: relative;
  background-color: #fff;
}

.help-box h5 {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.36
}

.help-box p {
  color: #252B42;
  font-size: 400;
  letter-spacing: -0.36px;
  font-size: 12px;
  margin-bottom: 0;
}

.help-icon {
  background: #EE0000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  padding: 15px;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.help-icon img {
  width: 100%;
  height: 100%;
}

.bg-dark {
  background-color: #000;
}

.client-area.logo-container {
  display: flex;
  gap: 30px 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.client-area.logo-container img {
  height: 3rem;
}


.dark-bg {
  position: relative;
  z-index: 0;
}

.dark-bg-after::after {
  position: relative;
  z-index: 0;
}

.dark-bg::after,
.dark-bg-after::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-image: url('/src//assets//images/dark-bg.png');
  background-size: 100% 100%;
  z-index: -1;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
}


.form-label {
  color: #989898;
  font-size: 14px;
  margin-bottom: 5px;
}

.form-control {
  margin-bottom: 10px;
}

h4 {
  font-family: Lato;
  font-family: "Montserrat", sans-serif;

  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  position: relative;
  padding-bottom: 15px;
}

.case-study-box p {
  color: #676767;
  font-size: 400;
  font-size: 14px;
  margin-bottom: 0;
}

.case-study-box ul li {
  color: #000;
  font-size: 400;
  font-size: 14px;
  margin-bottom: 5px;
}

.case-study-box ul {
  padding-left: 15px;
  margin-top: 20px;
}

.case-row {
  box-shadow: 0px 3px 18px 0px #00000040;
  border-radius: 20px;
  background-color: #fff;
}

.case-study-box {
  padding: 20px;
}

.fLogo {
  width: 150px;
}

footer {
  background: #000000;
}

.footer-area p {
  font-size: 14px;
  color: #E8E8E8 !important;
}

.copyright {
  border-top: 1px solid #B0BEC5;
  padding-top: 15px;
  text-align: center;
}

.footer-wrap ul li a {
  font-size: 14px;
  color: #E8E8E8 !important;
  font-weight: 600;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-decoration: none;
  letter-spacing: 0;
}

.footer-wrap ul {
  padding-left: 0;
}

.footer-wrap h5 {
  font-size: 17px;
  line-height: 33.6px;
  color: #fff;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.footer-wrap li {
  list-style: none;
}

.footer-wrap li img {
  width: 30px;
}

/*Inner Page*/
.gray-box {
  background: #EEEEEE;
  border-radius: 12px;
  padding: 40px 20px;
  box-shadow: 0px 4px 14px 0px #00000040;
}

.white-shadow-box {
  background: #FFF;
  border-radius: 12px;
  padding: 10px 5px;
  border: 1px solid #F5F5F5;
  box-shadow: 0px 4px 24px 0px #12121221;
  height: 90px;
}

.f-16 {
  font-size: 16px;
}

.f-14 {
  font-size: 14px;
}

.border-bottom-danger {
  border-bottom: 4px solid #E00;
}

.fw-600 {
  font-weight: 600;
}

.blue-shadow {
  box-shadow: 3.56px 3.56px 17.78px 0px #0895CC26;
}

.border-radius-12 {
  border-radius: 12px;
}

.blue-gradient {
  background: linear-gradient(180deg, #067BB1 0%, #04467A 100%);
}

.dark-gradient {
  background: linear-gradient(180deg, #272727 0%, #3d3d3d 100%);

}

.gradient-box h3 {
  font-family: Lato;
  font-family: "Montserrat", sans-serif;

  font-size: 18px;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  margin-bottom: 10px !important;
}

.gradient-box p {
  color: #fff;
  font-size: 12px;
  padding-right: 25px;
  font-weight: 400;
  line-height: normal;
}

.gradient-box {
  padding: 20px 15px;
  position: relative;
  z-index: 0;
}

.gradient-box::after {
  position: absolute;
  content: "";
  background-image: url(../images/Mask-group.png);
  background-repeat: no-repeat;
  width: 300px;
  height: 100%;
  right: 0;
  top: 0;
  background-size: 100%;
}

/* header.masthead.about-area {
  background-image: url('../images/bg-image.png');
} */

header.masthead.contact-area {
  background-image: url('../images/bg-contact.png');
}

.container-wrap::after {
  position: absolute;
  content: "";
  background: radial-gradient(50% 50% at 50% 50%, rgba(8, 149, 204, 0.4) 0%, rgba(8, 149, 204, 0) 100%);
  width: 270px;
  height: 267px;
  right: -35px;
  bottom: -43px;
}

.fs-13 {
  font-size: 13px;
}

.gradient_blue {
  background: linear-gradient(180deg, #067BB1 0%, #04467A 100%);

}

.drop-shadow {
  box-shadow: 0px 4px 15px 0px #00000040;
}

.rounded-icon {
  background: linear-gradient(180deg, #067BB1 0%, #04467A 100%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  padding: 15px;
}

.line-normal {
  line-height: normal;
}

.dropdown-item:active {
  background-color: #f8f8f8;
  color: #000;
}

/*Data Cloud Page*/
/* .data-cloud-header {
  background-image: url(../images//banner/banner-one.svg);
}


.markeing-cloud-pesonalization-banner {
  background-image: url(../images/banner/markeing-cloud-pesonalization-banner.png);
} */

/* .agent-force-service-banner {
  background-image: url(../images/banner/agent-force-service-banner.png);
} */

.about-banner {
  background-image: url(../images/banner/about-banner.png);
  background-size: cover !important;
}


.marketingCloudBanner {
  position: relative;
  /* background-image: url(../images/banner/markeing-cloud-banner.png); */
  background: linear-gradient(180deg, #067BB1 0%, #04467A 100%);
position: relative;
z-index: 0;
}

.marketingCloudBanner::before
{  
background-image: url(../images/layers/header-before.png);
position: absolute;
content: "";
left: 0;
top: 0;
width: 275px;
height: 230px;
background-size: 100%;
background-repeat: no-repeat;
z-index: -1;
}

.marketingCloudBanner::after
{
  background-image: url(../images/banner/scc.png);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  width: 500px;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}


/*data cloud banner*/
.dataCloudBanner {
  position: relative;
  /* background-image: url(../images/banner/markeing-cloud-banner.png); */
  background: linear-gradient(180deg, #067BB1 0%, #04467A 100%);
position: relative;
z-index: 0;
}

.dataCloudBanner::before
{  
background-image: url(../images/layers/header-before.png);
position: absolute;
content: "";
left: 0;
top: 0;
width: 275px;
height: 230px;
background-size: 100%;
background-repeat: no-repeat;
z-index: -1;
}

.dataCloudBanner::after
{
  background-image: url(../images/banner/data-cloud-banner-human.png);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  width: 500px;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

/*data cloud banner*/
.mCloudPersonalizationBanner {
  position: relative;
  /* background-image: url(../images/banner/markeing-cloud-banner.png); */
  background: linear-gradient(180deg, #067BB1 0%, #04467A 100%);
position: relative;
z-index: 0;
}

.mCloudPersonalizationBanner::before
{  
background-image: url(../images/layers/header-before.png);
position: absolute;
content: "";
left: 0;
top: 0;
width: 275px;
height: 230px;
background-size: 100%;
background-repeat: no-repeat;
z-index: -1;
}

.mCloudPersonalizationBanner::after
{
  background-image: url(../images/banner/mCloudPersonalizationHuman.png);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  width: 500px;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

/*agent force banner*/
.agentForceBanner {
  position: relative;
  /* background-image: url(../images/banner/markeing-cloud-banner.png); */
  background: linear-gradient(180deg, #067BB1 0%, #04467A 100%);
position: relative;
z-index: 0;
}

.agentForceBanner::before
{  
background-image: url(../images/layers/header-before.png);
position: absolute;
content: "";
left: 0;
top: 0;
width: 275px;
height: 230px;
background-size: 100%;
background-repeat: no-repeat;
z-index: -1;
}

.agentForceBanner::after
{
  background-image: url(../images/banner/agentForceBanner.png);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  width: 500px;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}


@media (max-width: 991px) {
  .marketingCloudBanner::after,
  .dataCloudBanner::after,
  .mCloudPersonalizationBanner::after,
  .agentForceBanner::after
  {
    display: none;
  }
}

.fancy-text {
  background: #028bc6;
  display: inline-flex;
  z-index: 0;
  position: relative;
}

.fancy-text::before {
  position: absolute;
  content: "";
  z-index: 2;
  right: -19px;
  width: 19px;
  height: 54.6667px;
  top: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url(../images/layers/text-after-shape.png);
}


.blue-gradient-sqr-icon {
  background: linear-gradient(180deg, #067BB1 0%, #04467A 100%);
  width: 70px;
  height: 70px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.w8-box::after {
  position: absolute;
  content: "";
  background-image: url(../images//icons/Subtract.png);
  content: "";
  right: 0;
  bottom: 0;
  width: 54px;
  height: 60px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: 3px solid #fff;
  border-bottom-right-radius: 0.1rem;
}

.box-back,
.blue-box-back {
  position: relative;
}

.box-back::after {
  position: absolute;
  content: "";
  background: #067BB1;
  width: 100%;
  height: 100%;
  left: 5px;
  top: 5px;
  border-radius: 2px;
}

.blue-box-back::after {
  position: absolute;
  content: "";
  background: #B5D6F6;
  width: 100%;
  height: 100%;
  left: 5px;
  top: 5px;
  border-radius: 2px;
}

.blue-box-back .w8-box::after {
  opacity: 0.1;
  border: transparent;
  border: 3px solid transparent;
}

.custom-ul li {
  display: flex;
  align-items: center;
  /* Centers marker vertically */
  gap: 10px;
  /* Space between marker and text */
}

.custom-ul li::before {
  content: "•";
  /* Unicode bullet */
  font-size: 0.7rem;
  /* Makes it smaller */
  color: #666;
  line-height: 1;
  /* Ensures vertical alignment */
}

.fancy-text2 {
  position: relative;
  display: inline-block;
  padding: 5px 10px;
  /* Adjust spacing */
  background: #028bc6;
  width: fit-content;
}

.fancy-text2::after {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  width: 10px;
  height: 100%;
  background: url('../images/layers/text-after-shape.png') no-repeat center center;
  background-size: 100% 100%;
  z-index: -1;
}

.fancy-text3 {
  position: relative;
  display: inline-block;
  padding: 5px 10px;
  /* Adjust spacing */
  background: #EE0000;
  width: fit-content;
}

.fancy-text3::after {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  width: 10px;
  height: 100%;
  background: url('../images/layers/text-after-shape-red.png') no-repeat center center;
  background-size: 100% 100%;
  z-index: -1;
}

.fancy-text4 {
  position: relative;
  display: inline-block;
  padding: 0px 10px;
  /* Adjust spacing */
  background: #028bc6;
  width: fit-content;
}

.fancy-text4::after {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  width: 10px;
  height: 100%;
  background: url('../images/layers/text-after-shape.png') no-repeat center center;
  background-size: 100% 100%;
  z-index: -1;
}

.award-box {
  width: 198px;
  height: 168px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.award-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 2px;
  border-radius: 5px;
}

.front-content {
  display: block;
}

.back-content {
  display: none;
}

.flip-card:hover .front-content {
  display: none;
}

.flip-card:hover .back-content {
  display: block;
}

.flip-card:hover {
  display: flex;
  align-items: center;
  background-color: #EE0000;
}

.flip-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 45px 30px;
  height: 175px;
}


.d-inline-block {
  white-space: nowrap;
  /* Prevents wrapping */
  width: auto;
  /* Fit to content */
}

.text-nowrap {
  white-space: nowrap;
}

.help-box p {
  line-height: 1.5em;
  /* Set the line height */
  min-height: calc(1.5em * 3);
  /* Height for 3 lines */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Force 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
}



@media (max-width: 768px) {
  
  .agent-force-service-banner {
    background-size: cover !important;
  }

  .data-cloud-header {
    background-size: cover !important;
  }

  .marketingCloudBanner {
    background-size: cover !important;
  }

  .markeing-cloud-pesonalization-banner {
    background-size: cover !important;
  }

  .about-banner {
    background-size: cover !important;
  }

}


.agent-force-service-banner {
  background-size: cover !important;
}

.white-card {
  padding: 40px 35px;
}

.footer-salesforce-logo {
  width: 100px;
}



